import React, { FunctionComponent } from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import { Page } from "../utils/models";
import Theme from "../styles/theme";
import { Container } from "../components/common";
import SEO from "../components/seo";
import Gallery from "../components/gallery/gallery";
import { Image } from "../utils/models";
import sanitizeHtml from 'sanitize-html';


interface GalleryTemplateProps {
  pageContext: {
    images: Image[];
    title: string;
    excerpt: string;
  };
  location: Location;
}
const PageContainer = styled(Container)`
  max-width: ${Theme.components.container.width};
  margin: 20px auto;

  padding: 0 15px;
  @media (max-width: ${Theme.breakpoints.sm}) {
    padding: 0 10px;
  }

`
const GalleryContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  @media (max-width: ${Theme.breakpoints.md}) {
    display: block;
  }

  padding: 15px;
  @media (maxwidth: ${Theme.breakpoints.sm}) {
    padding: 10px;
  }

  p:first-child {
    margin-top: 0;
  }

  li > a,
  p > a {
    color: ${Theme.layout.linkColor};
    border-bottom: 2px ${Theme.layout.linkColor} solid;
  }
`;

const SectionContainer = styled(Container)`
  padding: 0 30px;
  border: 1px #ededed solid;
  background-color: #fff;
`;

const PageSidebar = styled.aside`
  margin-left: 50px;

  @media (max-width: ${Theme.breakpoints.md}) {
    margin-left: 0;
  }
`;

const Title = styled.h1`
  
`;

const Caption = styled.div`
  a {
    color: ${Theme.layout.primaryColor};
  }
`;

const GalleryTemplate: FunctionComponent<GalleryTemplateProps> = ({ pageContext, location }) => {
  const images = pageContext.images.map(f => ({
    ...f.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    full: f.childImageSharp.full,
    thumb: f.childImageSharp.thumb,
    title: f.name,
    caption: f.childImageSharp.meta.originalName,
  }))

  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {

  }

  //Add callback to Lightbox onCloseRequest
  const onClose = () => {
    console.log('Lightbox was closed')
  }

  const options = {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['href', 'target']
    }
  } //Check documentation for reference

  return (
    <Layout bigHeader={false} fixedLogo={true} dark={true} >
      <PageContainer>
        <Title>{pageContext.title}</Title>
        <Caption dangerouslySetInnerHTML={{
          __html: sanitizeHtml(pageContext.excerpt, options)
        }}/>
        <GalleryContainer>
          <Gallery
            images={images}
            lightboxOptions={lightboxOptions}
            onClose={onClose}
          />
        </GalleryContainer>
      </PageContainer>
    </Layout>
  );
};

export default GalleryTemplate;
